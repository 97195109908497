import * as React from "react"
import { Link, graphql } from "gatsby"
import theme from "../gatsby-plugin-theme-ui"
import { ThemeProvider } from "theme-ui"
import Bio from "../components/bio"
import SEO from "../components/seo"
import ShowcaseLink from "../components/ShowcaseLink"
import Section3 from '../images/Section3.png'
import Helmet from "react-helmet"
import { Global } from "@emotion/core"
import { globalStyles } from "../components/styles"
import AnimatedWave from "../components/AnimatedWave.js"
import { SectionWrap, ProjectList } from "../components/styles"
import { Layout, Styled } from "theme-ui"
import { Header, Hero, Section, ProjectItem, Footer } from "../components"
import { StaticImage } from "gatsby-plugin-image"
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import VideoWEBM from '../video/3nd Duovocal.webm'
import Fallback from '../images/iphone-read-translate.png'

//<StaticImage src="../images/iphone-read-translate.png" alt={''}  placeholder={'blurred'} fit={'cover'} height={800}  />
const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const [open, setOpen] = React.useState(false);

  const onOpenModal = (e) => {e.preventDefault();setOpen(true)};
  const onCloseModal = () => setOpen(false);



  return (
    <div>
      <Global styles={globalStyles} />
      <Header logoTxt={"siteLogoText"} />
      <SEO title={'Effortless language learning'}/>
      <SectionWrap>
        <Hero imageAlt={"hero.imageAl"} id="hero" />
      
<ShowcaseLink></ShowcaseLink>

      
        <Section   backgroundColor={theme.colors.primary}  light waveGradient={true}     enableWave={true}
          waveBottom={false}>

<div style={{ display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'end', margin:0,padding:0, width:'100vw', height:'100%'}}>

<div style={{maxWidth:'600px'}}>
  <h2 style={{color:'#fff', margin:'1rem'}}>Read and "Learn a language while you learn!"</h2>
  <p  style={{color:'#fff', margin:'1rem'}}>Read in a new language using our App. This reader features instant translation, interconnectivity with flashcard creation (by a single-touch) and bookmarks.</p>
</div>
</div>
</Section>
<div style={{height:'1500px',display:'flex', justifyContent:'center',alignItems:'space-between', marginTop:0,backgroundImage:`url(${Section3}),linear-gradient(190deg,#00b7ff,#00b7ff 31%,#2c146c 100%)`}}>
<div className="over-abosulte">
<StaticImage src="../images/iphone-read-translate.png" alt={''}  placeholder={'blurred'} fit={'cover'} height={800}  />

<div style={{ display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'end', margin:0,padding:0}}>

<div style={{maxWidth:'600px',margin:'1rem'}}>
  <h2 style={{marginTop:0,marginBottom:0,color:'#fff'}}>Touch to Translate

</h2>
<p  style={{color:'#fff', marginTop:'1rem'}}>Simply touch words or phrases to instantly translate them.</p>

<h2 style={{marginTop:'1rem', marginBottom:0,color:'#fff'}} >"Our unique approach to Vocabulary Flashcards."</h2>
<p  style={{color:'#fff', marginTop:'1rem'}}>Our approach is unique and delivers extraordinary results. 
</p>
<h2 style={{marginTop:'1rem', marginBottom:0,color:'#fff'}} >"Keep Track of What You Already Know"</h2>
<p  style={{color:'#fff', marginTop:'1rem'}}>As your knowledge of the language increases so will your Word List developing and growing into your own personal dictionary.
</p>


</div>
</div>
</div>

</div>
<div style={{position:'absolute',width:'100%',opacity:1,transform:'translateY(-10rem)', height:'20rem', zIndex:1, alignItems:'center'}}>
  
<ProjectList>
  <ProjectItem
                      key={1}
                      image={null}
                      alt={'alt'}
                      name={'App Showcase'}
                      badge={'Details'}
                      link={'/showcase'}
                    /> 
                     <ProjectItem
                      key={1}
                      image={null}
                      alt={'alt'}
                      name={'Profile'}
                      badge={'User'}
                      link={'/profile'}
                    />
                     <ProjectItem
                      key={1}
                      image={null}
                      alt={'alt'}
                      name={'Articles'}
                      badge={'Index'}
                      link={'./content'}
                    />
                     <ProjectItem
                      key={1}
                      image={null}
                      alt={'alt'}
                      name={'Create'}
                      badge={'Tools'}
                      link={'/create-flashcards'}
                    />
              
                    
                    </ProjectList>
</div>
<Section   
backgroundColor={theme.colors.primary}  light waveGradient2={true}     enableWave={true}
          waveBottom={true}>

 
          </Section>


      
        <Section
          backgroundColor={theme.colors.primary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
  
     
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
             marginTop:'10rem'
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/duovocal.app/"
                class="fa fa-instagram"
                title={'Vist Our Instagram'}
              >
                <StaticImage src="../images/instagram-brands.svg" alt={'Instagram Icon'} style={{color:'#f67a40'}} placeholder={'blurred'} fit={'contain'} height={50} /></a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/DuovocalApp/"
                class="fa fa-facebook"
                title={'Vist Our Facebook'}
              >
                <StaticImage src="../images/facebook-f-brand.svg" alt={'Facebook Icon'} style={{color:'#f67a40'}} placeholder={'blurred'} fit={'contain'} height={50} />
              </a>
            </div>
            
          </div>
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                href="/terms-and-conditions"
                class="fa fa-instagram"
                title={'Terms and Conditions'}
              >
                <StaticImage src="../images/balance-scale-right-solid.svg" alt={'Terms and Conditions Icon'} style={{color:'#f67a40'}} placeholder={'blurred'} fit={'contain'} height={50} /></a>
              <a
                href="privacy-policy"
                class="fa fa-facebook"
                title={'Privacy Policy'}
              >
                <StaticImage src="../images/user-lock-solid.svg" alt={'Privacy Icon'} style={{color:'#f67a40'}} placeholder={'blurred'} fit={'contain'} height={50} />
              </a>
              <a
                class="fa fa-facebook"
                title={'Contact'}
                onClick={(e)=>onOpenModal(e)}
              >
                <StaticImage src="../images/envelope-open-regular.svg" alt={'Contact Icon'} style={{color:'#f67a40'}} placeholder={'blurred'} fit={'contain'} height={50} />
              </a>

              
            </div>
            
          </div>
          <div>

      <Modal open={open} onClose={onCloseModal} center>
      <div>
        <h3>Contact Us</h3>
        <form action="/contact" method="post" onsubmit="this.submit(); this.reset(); return false;">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input name="name" id="name" style={{width: '100%'}} type="text" placeholder="Name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input name="email" id="email" style={{width: '100%'}} type="email" placeholder="Email" />
          </div>
          <div className="field half" style={{display: 'none'}}>
            <label htmlFor="repeat_email">Email</label>
            <input name="repeat_email" id="repeat_email" type="email" placeholder="Email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows={6} style={{width: '100%'}} placeholder="Message" defaultValue={""} />
          </div>
          <div className="actions">
            <input defaultValue="Send Message" className="button alt" style={{width: '100%'}} type="submit" />
          </div>
        </form>
      </div>
      </Modal>
    </div>
        </Section>
      </SectionWrap>
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
